<template>
  <div
    :id="popupId"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myLargeModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h3 style="text-align:center;">Addresses of pickl tasks</h3>
          <input type="hidden" id="addresses-copy-text" v-model="copyText">

        </div>
        <div class="modal-body">
          <ul class="nav nav-tabs" role="tablist">
            <li role="presentation" class="active">
              <a
                href="#addresses"
                aria-controls="addresses"
                role="tab"
                data-toggle="tab"
              >
                Addresses
              </a>
            </li>
            <li role="presentation">
              <a
                href="#cities"
                aria-controls="cities"
                role="tab"
                data-toggle="tab"
              >
                City, state
              </a>
            </li>
            <li role="presentation">
              <a
                href="#zip-codes"
                aria-controls="zip-codes"
                role="tab"
                data-toggle="tab"
              >
                Zip Codes
              </a>
            </li>
            <li role="presentation">
              <a
                href="#states"
                aria-controls="states"
                role="tab"
                data-toggle="tab"
              >
                States
              </a>
            </li>
          </ul>
          <div class="tab-content">
            <div role="tabpanel" class="tab-pane fade in active" id="addresses">
              <br />
              <a
                href="javascript:;"
                @click="copyPicklAddress('addresses')"
                class="pull-right btn btn-positive"
                >Copy Addresses</a
              >
              <div class="clearfix"></div>

              <ul>
                <li
                  class="address-pickl-list"
                  v-for="pickl in pickls"
                  :key="pickl.id"
                >
                  {{ pickl.store_location.address }}
                </li>
              </ul>
            </div>
            <div role="tabpanel" class="tab-pane fade in " id="cities">
              <br />
              <a
                href="javascript:;"
                @click="copyPicklAddress('cities')"
                class="pull-right btn btn-positive"
                >Copy Cities</a
              >
              <div class="clearfix"></div>
              <ul>
                <li class="address-pickl-list" v-for="pickl in pickls" :key="pickl.id">
                  {{ pickl.store_location.city }}, {{pickl.store_location.state}}
                </li>
              </ul>
            </div>

            <div role="tabpanel" class="tab-pane fade in " id="zip-codes">
              <br />
              <a
                href="javascript:;"
                @click="copyPicklAddress('zip-codes')"
                class="pull-right btn btn-positive"
                >Copy Zip Codes</a
              >
              <div class="clearfix"></div>

              <ul>
                <li class="address-pickl-list" v-for="pickl in pickls" :key="pickl.id">
                  {{ pickl.store_location.zip_code }}
                </li>
              </ul>
            </div>
            <div role="tabpanel" class="tab-pane fade in " id="states">
              <br />
              <a
                href="javascript:;"
                @click="copyPicklAddress('states')"
                class="pull-right btn btn-positive"
                >Copy States</a
              >
              <div class="clearfix"></div>

              <ul>
                <li class="address-pickl-list" v-for="pickl in pickls" :key="pickl.id">
                  {{ pickl.store_location.state }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AddressesListPopup',
  props: ['popupId', 'pickls'],
  data: function () {
    return {
      copyText: 'hello'
    }
  },
  methods: {
    copyPicklAddress (type) {
      let arr = []
      this.pickls.forEach((pickl) => {
        if (type === 'addresses') {
          if (arr.indexOf(pickl.store_location.address) === -1) {
            arr.push(pickl.store_location.address)
          } else {
          }
        } else if (type === 'cities') {
          let city = pickl.store_location.city + ', ' + pickl.store_location.state
          if (arr.indexOf(city) === -1) {
            arr.push(city)
          }
        } else if (type === 'states') {
          if (arr.indexOf(pickl.store_location.state) === -1) {
            arr.push(pickl.store_location.state)
          }
        } else if (type === 'zip-codes') {
          if (arr.indexOf(pickl.store_location.zip_code) === -1) {
            arr.push(pickl.store_location.zip_code)
          }
        }
      })
      this.copyText = arr.join('; ')
      let testingCodeToCopy = document.querySelector('#addresses-copy-text')
      testingCodeToCopy.setAttribute('type', 'text')
      testingCodeToCopy.select()

      try {
        var successful = document.execCommand('copy')
        var msg = successful ? 'Successful' : 'Unsuccessful'
        this.$notify({ type: 'success', text: 'Copying Text ' + msg })
      } catch (err) {
        alert('Oops, unable to copy')
      }
      // testingCodeToCopy.setAttribute('type', 'hidden')
      // window.getSelection().removeAllRanges()
    }
  }
}
</script>

<style scoped>
p {
  color: #232323;
  font-size: 24px;
  text-align: center;
}
.address-pickl-list {
  border: solid 1px rgb(230, 227, 227);
  padding: 5px;
}
</style>
