<template>
  <div
    :id="popupId"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myLargeModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h2>POP | PICKL Optimization Program</h2>
          <button
            ref="popupCloseButton"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
            <div class="row" style="border:1px solid #12db6d; padding: 2%;" v-if="step == 1">
                <h3 style="margin-left:1%;text-align:center;color:black;">How would you like to re-launch the tasks? </h3>
                <small style="text-align:center; display:block; width:100%;">(Tasks will only pop to locations not completed in the last 30 days.)</small>
                <br/><br/>
                <div class="col-md-4">
                    <div class="green-border-box white-bg text-center pickl" @click="selectPopType('same-state-same-retailer')" :class="selectedPopOption=='same-state-same-retailer'?'pickl-selected':''">
                        <h3>POP #1</h3>
                        <h3>SAME RETAILER<br/>
                        SAME STATE</h3>
                        <small style="color:#782b54;"><span style="text-decoration:underline;">Different:</span><br/> store locations</small>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="green-border-box white-bg text-center pickl" @click="selectPopType('any-state-same-retailer')" :class="selectedPopOption=='any-state-same-retailer'?'pickl-selected':''">
                       <h3>POP #2</h3>
                       <h3>SAME RETAILER<br/>
                        ANY STATE</h3>
                       <small style="color:#782b54;"><span style="text-decoration:underline;">Different:</span><br/> store locations + states</small>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="green-border-box white-bg text-center pickl" @click="selectPopType('any-state-any-retailer')" :class="selectedPopOption=='any-state-any-retailer'?'pickl-selected':''">
                        <h3>POP #3</h3>
                        <h3>ANY RETAILER<br/>
                        ANY STATE</h3>
                        <small style="color:#782b54;"><span style="text-decoration:underline;">Different:</span><br/> retailers + states + store locations</small>
                    </div>
                </div>
                <div class="col-sm-12">
                  <hr/>

                  <h2>Select Audience?</h2>
                  <div class="col-sm-3">
                    <input type="radio" v-model="audience" value="new-users" id="audience-new-users"/><label for="audience-new-users" style="margin-left: 10px;"> New Registered Users</label><br/>
                  </div>
                  <div class="col-sm-3">
                    <input type="radio" v-model="audience" value="active-locations" id="audience-active-locations"/><label for="audience-active-locations" style="margin-left: 10px;"> Most Active Locations</label><br/>
                  </div>
                  <div class="col-sm-3">
                    <input type="radio" v-model="audience" value="active-users" id="audience-active-users"/><label for="audience-active-users" style="margin-left: 10px;"> Most Active Users</label>
                  </div>
                  <div class="col-sm-3">
                    <input type="radio" v-model="audience" value="pickl-requests" id="audience-pickl-requests"/><label for="audience-pickl-requests" style="margin-left: 10px;"> Recent Pickl Requests</label>
                  </div>
                </div>
                <div class="col-sm-12" v-if="selectedPopOption == 'any-state-any-retailer' || selectedPopOption == 'any-state-same-retailer'">
                  <hr/>

                  <h2>Select states you want to pop</h2>
                  <div class="col-sm-12">
                  <input type="checkbox"  v-model="selectAllStates" id="select-all-states" @change="toggleSelectedStates"/><label :for="'select-all-states'">Select All States</label>
                  </div>
                  <div class="col-sm-2" v-for="state in states" :key="state.id">
                    <input type="checkbox" :id="'state_'+state.id" v-model="selectedStates" :value="state.id" /><label :for="'state_'+state.id">{{ state.short_name }}</label>
                  </div>
                </div>
            </div>
            <div class="row">
                <br/>
                <!-- <button class="pull-left btn-negative" v-if="step > 1" @click="backStep">Back</button>
                <button class="pull-right  btn-positive" v-if="step == 1" @click="nextStep">Next</button> -->
                <button class="pull-right  btn-positive" :disabled="submittingForm" @click="popSelectedPickls" v-html="popButtonLabel"></button>

            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PicklItem from '@/components/common/PicklItem.vue'
import { indexOf } from '@amcharts/amcharts4/.internal/core/utils/Array'
export default {
  props: ['popupId', 'picklIds'],
  components: {
    PicklItem
  },
  data: function () {
    return {
      selectAllStates: 0,
      step: 1,
      selectedPicklsList: [],
      hoverRating: 0,
      rating: 0,
      adminNote: '',
      needReview: '',
      ShowNotesToBrand: 1,
      selectedPopOption: 'same-state-same-retailer',
      popButtonLabel: 'POP',
      submittingForm: false,
      states: [],
      selectedStates: [],
      audience: 'new-users'
    }
  },
  mounted () {
    this.getStates()
  },
  methods: {
    toggleSelectedStates () {
      console.log(this.selectAllStates)
      if (this.selectAllStates) {
        this.selectedStates = []
        this.states.forEach((state) => {
          this.selectedStates.push(state.id)
        })
      } else {
        this.selectedStates = []
      }
    },
    getStates () {
      this.states = []
      this.$http.get('states?country_id=' + 234).then((response) => {
        this.states = response.body.data
      })
    },
    isSelectedPickl (picklId) {
      if (this.selectedPicklsList.includes(picklId)) {
        return true
      } else {
        return false
      }
    },
    toggleSelectedPicklList (picklId) {
      if (this.selectedPicklsList.includes(picklId)) {
        this.selectedPicklsList.splice(this.selectedPicklsList.indexOf(picklId), 1)
      } else {
        this.selectedPicklsList.push(picklId)
      }
    },
    nextStep () {
      this.step = this.step + 1
    },
    backStep () {
      this.step = this.step - 1
    },
    selectPopType (option) {
      this.selectedPopOption = option
    },
    popSelectedPickls () {
      this.popButtonLabel = 'Popping Now, Please wait...'
      this.submittingForm = true
      let payload = { picklIds: this.picklIds, option: this.selectedPopOption, stateIds: this.selectedStates, audience: this.audience }
      console.log(payload)
      this.$store.dispatch('popPickls', payload).then((response) => {
        console.log(response.body)
        this.popButtonLabel = 'POP'
        this.submittingForm = false
        this.$emit('submitted')
        this.$notify({ type: 'success', text: response.body.message, duration: 5000 })

        this.$refs.popupCloseButton.click()
      })
    }
  }
}
</script>
<style scoped>
p {
  color: #232323;
  font-size: 24px;
  text-align: center;
}
.rate-picklr {
  cursor: pointer;
  margin-bottom: 45px;
}

.rate-picklr span {
  display: inline-block;
}

.rate-picklr img {
  width: 33px;
  height: 31px;
  margin: 0 8px;
}
table.user-pickl-table>tr {
  border: solid 1px gainsboro;
}
table.user-pickl-table>tr>td {
  padding: 5px 5px;
}

.bottom-margin {
    margin-bottom: 10px;
}
.pickl-selected {
  box-shadow: 0px 0px 10px 2px #12db6d;
}
.pickl {
    cursor: pointer;
}
</style>
