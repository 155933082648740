var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        id: _vm.popupId,
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "myLargeModalLabel",
        "aria-hidden": "true"
      }
    },
    [
      _c(
        "div",
        { staticClass: "modal-dialog modal-lg modal-dialog-centered" },
        [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c("h3", { staticStyle: { "text-align": "center" } }, [
                _vm._v("Addresses of pickl tasks")
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.copyText,
                    expression: "copyText"
                  }
                ],
                attrs: { type: "hidden", id: "addresses-copy-text" },
                domProps: { value: _vm.copyText },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.copyText = $event.target.value
                  }
                }
              })
            ]),
            _c("div", { staticClass: "modal-body" }, [
              _vm._m(0),
              _c("div", { staticClass: "tab-content" }, [
                _c(
                  "div",
                  {
                    staticClass: "tab-pane fade in active",
                    attrs: { role: "tabpanel", id: "addresses" }
                  },
                  [
                    _c("br"),
                    _c(
                      "a",
                      {
                        staticClass: "pull-right btn btn-positive",
                        attrs: { href: "javascript:;" },
                        on: {
                          click: function($event) {
                            return _vm.copyPicklAddress("addresses")
                          }
                        }
                      },
                      [_vm._v("Copy Addresses")]
                    ),
                    _c("div", { staticClass: "clearfix" }),
                    _c(
                      "ul",
                      _vm._l(_vm.pickls, function(pickl) {
                        return _c(
                          "li",
                          { key: pickl.id, staticClass: "address-pickl-list" },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(pickl.store_location.address) +
                                "\n              "
                            )
                          ]
                        )
                      }),
                      0
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "tab-pane fade in ",
                    attrs: { role: "tabpanel", id: "cities" }
                  },
                  [
                    _c("br"),
                    _c(
                      "a",
                      {
                        staticClass: "pull-right btn btn-positive",
                        attrs: { href: "javascript:;" },
                        on: {
                          click: function($event) {
                            return _vm.copyPicklAddress("cities")
                          }
                        }
                      },
                      [_vm._v("Copy Cities")]
                    ),
                    _c("div", { staticClass: "clearfix" }),
                    _c(
                      "ul",
                      _vm._l(_vm.pickls, function(pickl) {
                        return _c(
                          "li",
                          { key: pickl.id, staticClass: "address-pickl-list" },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(pickl.store_location.city) +
                                ", " +
                                _vm._s(pickl.store_location.state) +
                                "\n              "
                            )
                          ]
                        )
                      }),
                      0
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "tab-pane fade in ",
                    attrs: { role: "tabpanel", id: "zip-codes" }
                  },
                  [
                    _c("br"),
                    _c(
                      "a",
                      {
                        staticClass: "pull-right btn btn-positive",
                        attrs: { href: "javascript:;" },
                        on: {
                          click: function($event) {
                            return _vm.copyPicklAddress("zip-codes")
                          }
                        }
                      },
                      [_vm._v("Copy Zip Codes")]
                    ),
                    _c("div", { staticClass: "clearfix" }),
                    _c(
                      "ul",
                      _vm._l(_vm.pickls, function(pickl) {
                        return _c(
                          "li",
                          { key: pickl.id, staticClass: "address-pickl-list" },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(pickl.store_location.zip_code) +
                                "\n              "
                            )
                          ]
                        )
                      }),
                      0
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "tab-pane fade in ",
                    attrs: { role: "tabpanel", id: "states" }
                  },
                  [
                    _c("br"),
                    _c(
                      "a",
                      {
                        staticClass: "pull-right btn btn-positive",
                        attrs: { href: "javascript:;" },
                        on: {
                          click: function($event) {
                            return _vm.copyPicklAddress("states")
                          }
                        }
                      },
                      [_vm._v("Copy States")]
                    ),
                    _c("div", { staticClass: "clearfix" }),
                    _c(
                      "ul",
                      _vm._l(_vm.pickls, function(pickl) {
                        return _c(
                          "li",
                          { key: pickl.id, staticClass: "address-pickl-list" },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(pickl.store_location.state) +
                                "\n              "
                            )
                          ]
                        )
                      }),
                      0
                    )
                  ]
                )
              ])
            ])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "ul",
      { staticClass: "nav nav-tabs", attrs: { role: "tablist" } },
      [
        _c("li", { staticClass: "active", attrs: { role: "presentation" } }, [
          _c(
            "a",
            {
              attrs: {
                href: "#addresses",
                "aria-controls": "addresses",
                role: "tab",
                "data-toggle": "tab"
              }
            },
            [_vm._v("\n              Addresses\n            ")]
          )
        ]),
        _c("li", { attrs: { role: "presentation" } }, [
          _c(
            "a",
            {
              attrs: {
                href: "#cities",
                "aria-controls": "cities",
                role: "tab",
                "data-toggle": "tab"
              }
            },
            [_vm._v("\n              City, state\n            ")]
          )
        ]),
        _c("li", { attrs: { role: "presentation" } }, [
          _c(
            "a",
            {
              attrs: {
                href: "#zip-codes",
                "aria-controls": "zip-codes",
                role: "tab",
                "data-toggle": "tab"
              }
            },
            [_vm._v("\n              Zip Codes\n            ")]
          )
        ]),
        _c("li", { attrs: { role: "presentation" } }, [
          _c(
            "a",
            {
              attrs: {
                href: "#states",
                "aria-controls": "states",
                role: "tab",
                "data-toggle": "tab"
              }
            },
            [_vm._v("\n              States\n            ")]
          )
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }